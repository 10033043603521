.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: var(--z-nav);
  border-bottom: 0.5rem solid var(--main-color);
}

.navbarTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
  margin: 0 var(--m-0-5);
}

.navbarTop__contact {
  display: flex;
  gap: 0.5rem;
  font-size: var(--smaller-font-size);
  font-weight: var(--font-light);
  align-items: center;
}

.navbarTop__social {
  display: flex;
  align-items: center;
  font-size: var(--normal-font-size);
  gap: 0.25rem;
}

.navbarBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.25rem;
  margin: 0 var(--m-2);
}

.navbarBottom__logo {
  width: 4rem;
  height: 4rem;
  display: flex;
}

.navbarBottom__logo .logout__text{
  margin-left: 1rem;
  margin-top: 2.5rem;
  color: red;
}

.navbarBottom__navMenu {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbarBottom__navList {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(5, auto);
}

.navbarBottom__navItem {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
}

.navbarBottom__button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.navbarBottom__menuIcon {
  display: none;
  cursor: pointer;
}

/* BREAKPOINTS */
/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {
  .navbarBottom__navMenu {
    border-left: 0.25rem solid var(--main-color);
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    bottom: 0;
    right: -500px;
    height: calc(100% - var(--header-height));
    width: 60%;
    background-color: var(--bg-color);
    z-index: -50;
    transition: 0.3s ease-in-out;
  }

  .navbarBottom__navMenu.active {
    right: -1px;
  }

  .navbarBottom__navList {
    margin: auto;
    grid-template-columns: repeat(1, auto);
    gap: 3rem;
  }

  .navbarBottom__navItem {
    font-size: var(--h1-font-size);
    font-weight: var(--font-semi-bold);
  }

  .navbarBottom__button.button {
    display: none;
  }

  .navbarBottom__menuIcon {
    display: block;
    font-size: var(--h3-font-size);
  }
}
