.classes {
  min-height: calc(100vh - var(--header-height));
  background-color: var(--bg-color);
  display: flex;

}

.classes__container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section__subtitle{
  color: var(--main-color);
  margin-bottom: var(--m-2);
}

.admin__text{
  margin-bottom: 5px;
}

.pricing__container{
  display: flex;
  width: 100%;
  gap: 2rem;
}

.logo__left{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo__left img{
  max-width: 200px;
}

.logo__text{
  text-align: center;
  border: 5px solid var(--text-color);
  background-color: var(--second-bg-color);
  width: 100%;
  padding: 0 1rem;
}

.info__title{
  color: var(--second-text-color);
  font-weight: var(--font-semi-bold);
  font-size: 3rem;
}

.info__subTitle{
  font-size: 1.7rem;
}


.pricing__right{
  flex: 2;
}

.product__title{
  font-size: 1rem;
}

.option__button{
  padding: 1px 3px;
}

.price__container{
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  gap: .5rem;
}

.price__line{
  width: 100%;
  border-bottom: 1px dashed;
}

.disclaimer{
  margin-top: var(--m-1);
  text-align: center;
  font-size: var(--small-font-size);
  color: var(--second-bg-color);
}

/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {

}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {
  .pricing__container{
    flex-direction: column;
    min-height: 100vh;
  }
}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {

}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {

}













/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {

}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {

}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {

  
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {


}
