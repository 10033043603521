.about {
  min-height: calc(100vh - var(--header-height));
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
}

.about__container {
  position: relative;
  min-height: 65vh;
  display: flex;
  justify-content: center;
}

.about__container img {
  object-fit: cover;
  opacity: 0.45;
}

.about__overlay {
  position: absolute;
  z-index: var(--z-modal);
  background-color: var(--bg-color);
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  opacity: .6;
}

.about__text {
  position: absolute;
  z-index: var(--z-fixed);
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

.about__text span {
  color: var(--main-color);
}

.about__text p {
  font-weight: var(--font-light);
  margin-top: var(--m-1);

}

/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {
}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {
  .about__overlay {
    width: 65%;
  }

  .about__text {
    width: 65%;
  }
}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {
  .about__overlay {
    width: 100%;
  }

  .about__text {
    width: 100%;
    padding: 0 0.5rem;
  }
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {
}
