.login{
  height: 100vh;
  width: 100%;
  background-color: var(--second-bg-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container {
  height: 100%;
  max-width: 100%;
  background-color: var(--bg-color);
  padding: 0 2rem;
  border-radius: 1rem;
  display: flex;
  overflow: hidden;
}

.left{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left img{
  width: 15rem;
  height: 15rem;
  
}

.right{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.right .login__title{
  margin-bottom: 1rem;
}

.right form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  
}

.right input {
  font-family: var(--body-font);
  height: 50px;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 10px;
  background: var(--bg-color);
  border: 3px solid var(--main-color);
  outline: none;
  color: var(--main-color);
  width: 100%;
  letter-spacing: 1px;
  
  
}



/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {
  
}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {
  
}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {
  .login{
    background-color: var(--bg-color);
  }

  .login__container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {
  
}