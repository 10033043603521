.footer{
  background-color: #141414;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1rem 0; 
}

.footer .footer__container{
  text-align: center;
  justify-content: center;
  align-items: center;
}

.footer .footer__text{
  margin: 10px;
  font-size: 1rem;
  color: var(--main-color);
}

.footer__copyright{
  padding-top: 10px;
  font-size: var(--smaller-font-size);
}