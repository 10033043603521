.offer {
  min-height: calc(100vh - var(--header-height));
  background-color: var(--second-bg-color);
  display: flex;
}

.offer__container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.section__subtitle{
  color: var(--main-color);
}


.offer__cardContainer{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 200px); 
  gap: 2rem;
  text-align: center;
  width: 100%;
}

.offerCard{
  cursor: pointer;
  transition: .3s ease-in-out;
  position: relative;
  margin-top: var(--m-2);
  box-shadow: 0px 4px 3px rgba(0,0,0,0.4), 
  0px 8px 13px rgba(0,0,0,0.1), 
  0px 18px 23px rgba(0,0,0,0.1);
}

.offerCard:hover{
  scale: 1.1;
}


.offerCard__img{
  position: absolute;
  width: 100%;
  height: 100%;
}

.offerCard__img img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.offerCard__overlay{
  position: absolute;
  z-index: var(--z-modal);
  background-color: black;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: .6;
}

.offerCard__text{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: var(--z-fixed);
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.4),
  0px 18px 23px rgba(0, 0, 0, 0.4);
  padding: 1rem;
}

/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {

}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {

}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {
  .offer__cardContainer{
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 200px); 
    gap: 1rem;
  }
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {

}







