.popupImage__container {
  z-index: 100;
  overflow: hidden;
  position: fixed;
  margin: var(--header-height) 0;
  background-color: black;
  transform: translate(-50%, -50%);
  border: 2px solid var(--text-color);
  top: 0%;
  left: 0%;
  max-width: 0%;
  height: 0%;
  display: none;
  box-shadow: 0px 4px 3px rgba(0,0,0,0.4), 
  0px 8px 13px rgba(0,0,0,0.1), 
  0px 18px 23px rgba(0,0,0,0.1);
}

.popupImage__container.show {
  max-width: 70%;
  width: 768px;
  height: 70%;
  top: 45%;
  left: 50%;
  display: flex;
}

.image__popup{
  width: 100%;
  height: 100%;
}

.image__popup img{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {
}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {

}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {
  
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {
}