.home__overlay {
  position: fixed;
  width: 100%;
  min-height: calc(100% - var(--header-height));
  z-index: 100;
  background-color: var(--main-color);
  opacity: 0.25;
  display: none;
}

.home__overlay.show {
  display: block;
}

