:root {
  --header-height: 7.5rem;

  /* COLORS */
  --main-color: #429bce;
  --second-main-color: #002d4e;
  --bg-color: #000;
  --second-bg-color: #545454;
  --text-color: #f0edee;
  --second-text-color: #000;

  /* FONT */
  --body-font: "Kanit", san-serif;

  /* .5rem = 8px | 1rem = 16px */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* FONT WEIGHT */
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* MARGIN BOTTOM */
  --m-0-5: 0.5rem;
  --m-1: 1rem;
  --m-2: 2rem;
  --m-5: 5rem


  /* Z INDEX */
  --z-modal: 25;
  --z-fixed: 50;
  --z-nav: 200;
}

/* RESPONSIVE TYPO */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* BASE */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  min-width: 370px;
  min-height: 100vh;
}

body,
input,
button,
textarea {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  
}

body {
  color: var(--text-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}


img {
  height: auto;
  max-width: 100%;
}

section{
  padding: 4rem 0; 
}

.section__title{
  font-size: var(--big-font-size);
  line-height: 3rem;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.4),
  0px 18px 23px rgba(0, 0, 0, 0.4);
}

.section__subtitle{
  font-size: var(--h3-font-size);
}

/* LAYOUT */
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

/* BUTTON */
.button{
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 2px solid var(--main-color);
  border-radius: 3rem;
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
}

.button-admin{
  border: 5px solid red;
  border-radius: 0rem;
  cursor: pointer;

}

/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {

}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {

}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {

  :root{
    --big-font-size: 2.20rem
  }
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {

}
