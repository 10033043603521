.gallery {
  min-height: calc(100vh - var(--header-height));
  background-color: var(--second-bg-color);
  display: flex;
}

.gallery__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 200px);
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
}

.imageCard {
  position: relative;
  width: 100%;
}

.imageCard img {
  object-fit: contain;
  height: 150px;
  width: 100%;
}

.imageCard .delete__img{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-fixed);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(209, 0, 0, 0.443);
}

.imageCard .trash-icon{
  scale: 4;
}

.load__more{
  background: none;
  margin-top: var(--m-2);
  font-size: var(--h3-font-size);
  color: var(--bg-color);
  cursor: pointer;
}



/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {
}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {
}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {
  .image__container {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 450px) {
  .image__container {
    grid-template-columns: repeat(3, 1fr);
    gap: .5rem;
  }
}
