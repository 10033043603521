.hero {
  min-height: calc(100vh - var(--header-height));
  margin-top: var(--header-height);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero__img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4), 
  0px 8px 13px rgba(0,0,0,0.1), 
  0px 18px 23px rgba(0,0,0,0.1);
}

.hero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero__overlay {
  position: absolute;
  background-color: var(--bg-color);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  z-index: var(--z-modal);
}

.hero__info {
  z-index: var(--z-fixed);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transform: translate(0, 0);
  padding: 0 var(--m-0-5);
}

.hero__info img {
  height: 200px;
  width: 200px;
  margin-bottom: var(--m-0-5);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.hero__info h1 {
  font-size: var(--big-font-size);
}

.hero__info h2 {
  font-size: var(--h1-font-size);
}

.hero__info h3 {
  font-size: var(--normal-font-size);
  margin-bottom: var(--m-0-5);
  font-weight: var(--font-normal);
  margin-bottom: var(--m-2);
}

.hero__button {
  padding: 0.75rem 1.5rem;
}
