.popup__container {
  z-index: 100;
  overflow: hidden;
  position: fixed;
  margin: var(--header-height) 0;
  background-color: black;
  transform: translate(-50%, -50%);
  border: 2px solid var(--text-color);
  top: 0%;
  left: 0%;
  max-width: 0%;
  height: 0%;
  display: none;
  box-shadow: 0px 4px 3px rgba(0,0,0,0.4), 
  0px 8px 13px rgba(0,0,0,0.1), 
  0px 18px 23px rgba(0,0,0,0.1);
}

.popup__container.show {
  max-width: 70%;
  width: 768px;
  height: 70%;
  top: 45%;
  left: 50%;
  display: flex;
}

.popup__left {
  position: relative;
  width: 50%;
}

.popup__left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.10;
}

.popup__course {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.popup__right {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup__button {
  display: inline-flex;
  justify-content: end;
    cursor: pointer;
}



/* BREAKPOINTS */
/* FOR LARGE DEVICE */
@media screen and (max-width: 992px) {
}

/* FOR MEDIUM DEVICE */
@media screen and (max-width: 768px) {
}

/* FOR TABLET DEVICE */
@media screen and (max-width: 576px) {
  .popup__container {
    flex-direction: column;
  }

  .popup__left {
    width: auto;
    height: 20%;
  }

  .popup__course {
    padding: 0.5rem;
  }

  .popup__right {
    width: auto;
    height: 100%;
    padding: 0.5rem;
  }
}

/* FOR SMALL DEVICE */
@media screen and (max-width: 350px) {
}
